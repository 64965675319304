import React from "react";
import { Link } from "gatsby";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.css";
// import { Modal, Button } from "antd";
 import Layout from "../components/header";
 import axios from "axios";
 import { graphql } from 'gatsby';
 import {navigate } from 'gatsby';
 import HeaderLight from "./../components/headerLight";
 import Footer from "./../components/footer";
import { element } from "prop-types";
import { Spin } from "antd";
// import SweetAlert from "sweetalert-react";
// import Pagination from "react-js-pagination";
// require("bootstrap/dist/css/bootstrap.css");
// require("sweetalert/dist/sweetalert.css");
 require("../css/registration.css");
 var passwordHash = require('password-hash');

 //var globalemail;

class Registration extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      displayEmail: "none",
      displayEmailInvalid: "none",
      displayPassword:"none",
      //data:false,
      displayalreadyexit:false,
      alreadyhaveemail:"none",
      emailpws:false,
      invalidPws:"none",
      redirect:"none",
      displayName:"none",
      displayInvalidPassword:"none",
      Spin:false,

      
     

    };
  }


  // componentDidMount(){
  //   var test={
  //     username:"anil",
  //     password:"anilgp"
  //   }
  //   axios
  //     .post("https://dashboard.nativecircle.in/wp-json/api/login",test)
  //     .then(response=>{
  //      console.log(response,"check data");
  //     })
  // }
  proceedtoLogin(){
    this.setState({
      Spin:true
    })



    var _this = this;
      var bodyFormData = new FormData();
  
      var email = document.getElementById("email").value;
      var password=document.getElementById("password").value;
      
       var name=document.getElementById("name").value;
      
     // globalemail =email;
     // console.log(email+password )
      var stringify;
      
       if (email == "" || this.state.alreadyhaveemail =="block"||this.state.displayEmailInvalid=="block" ) {
        this.validateEmail();
        this.setState({Spin:false})
      } 
      else if(password == ""||this.state.displayPassword == "block"||this.state.displayInvalidPassword =="block")
      {
        this.validatePassword();
        this.setState({Spin:false})
      }
      else if(name == "")
      {
          this.validateName();
          this.setState({Spin:false})
      }
      else {
        document.getElementById("signupbutton").disabled=true
        var _this=this;
        var hashedPassword = passwordHash.generate(password);
       // alert(hashedPassword);
        window.sessionStorage.setItem("registeredemail",email);


        var bodyFormData = new FormData();

          bodyFormData.set("fullName",document.getElementById("name").value);
          bodyFormData.set("phone",0);
          bodyFormData.set("email",document.getElementById("email").value);
          bodyFormData.set("password",hashedPassword);
          
          bodyFormData.set("pincode",0);
          bodyFormData.set("building","");
          bodyFormData.set("area","");
          bodyFormData.set("landmark","");
          bodyFormData.set("town","");
          bodyFormData.set("state","");
          bodyFormData.set("type","registration");
          bodyFormData.set("formID", 1223);

            axios
            .post(
              "https://dashboard.nativecircle.in/index.php/wp-json/contact-form-7/v1/contact-forms/1223/feedback",
              bodyFormData,
              {
                headers: {
                  "Content-Type": "multipart/form-data"
                }

              }
            )
           
            .then(response => {
             // alert("the respose is"+response.status)
                 document.getElementById("form").reset();
                
                 if (response.status == 200 || response.status == 201) {
                 // console.log(response);
                  navigate("/profile/")
                   _this.setState({
                     showAlert: true,
                     Spin:false
                   });
                 }
               })
               .catch(err => {
               // alert(err);
               });
                

        // var infodata=this.props.data.allMysqlLeads.edges
        // var checkdata=infodata.filter(element=>{
        //     if( element.node.email==email && element.node.password==password)
        //     {
        //       return email;
        //     }
        //   })
        
        // if(checkdata.length==0)
        // {
        //   this.setState({invalidPws:"block"});
        //   // alert(checkdata.length)
        //   // alert("failure")
        // }
        // else
        // {
        //   this.setState({invalidPws:"none"});
        //   this.setState({redirect:"block"})
        //   // alert(checkdata.length)
        //   // alert("sussss")
        
        // }
       
        
     
      

  //   var info = this.props.data.allMysqlLeads.edges;
   
  //  for (let ele of info){
     
  //    if(ele.node.email===email){
      
     
  //      this.setState({
  //        displayalreadyexit:true
  //      })
  //      break;
      
  //    }
    
  //  }
   // console.log(this.state.data,"data")
      }
  }

  
  validateEmail() {
    
    //alert(this.state.alreadyhaveemail,"this is query result")
    var pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    var email = document.getElementById("email").value;
   
    if (email == "") {
      this.setState({ displayEmailInvalid: "none" });
      this.setState({alreadyhaveemail:"none"});
      this.setState({ displayEmail: "block" });

    } else {
      this.setState({ displayEmail: "none" });
      if (!pattern.test(email)) {
        //this.setState({alreadyhaveemail:"none"});
        this.setState({ displayEmailInvalid: "block" });
      } else {
        this.setState({ displayEmailInvalid: "none" });

        var logindata ={
          email:email,
        }
        axios.post("https://dashboard.nativecircle.in/wp-json/api/login",logindata)
        .then(response=>{
          if(response.data.status==200){ 
            this.setState({alreadyhaveemail:"block"});
          }
          else
          {
            this.setState({alreadyhaveemail:"none"});
          }
        })
        



        
        //var info = this.props.data.allMysqlLeads.edges;
        

        // var testdata=info.filter(ele=>{
        //   if( ele.node.email==email)
        //   {
        //     return email;
        //   }

        // })
        // if(testdata.length>0)
        // {
        //   //alert(testdata.length+"if");
        //   this.setState({alreadyhaveemail:"block"});
          
        // }
        // else
        // {
        //   //alert(testdata.length+"else");
        //   this.setState({alreadyhaveemail:"none"});
        // }
       }
     }
   }


   validatePassword()
  {
     var pattern=/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/
    var password=document.getElementById("password").value;
    if(password == "")
    {
      this.setState({displayPassword:"block"});
    }
    else
    {
      this.setState({displayPassword:"none"});
      if(!pattern.test(password))
      {
        this.setState({displayInvalidPassword:"block"});
      }
      else
      {
        this.setState({displayInvalidPassword:"none"});
      }
    }
  }



  validateName() {
    var name = document.getElementById("name").value;
    if (name == "") {
      this.setState({ displayName: "block" });
    } else {
      this.setState({ displayName: "none" });
    }
  }




  render() {
    
    return (
      <div>
          {/* <HeaderLight/> */}
          <Spin  tip="Loading..."
          spinning={this.state.Spin}
          wrapperClassName="spinClass">
      <div className="loginBackground">
      
      <div class="container signup-container-mobile">

      <div className="wrapper">
      <div className="loginFormcontent col-sm-12 col-xs-12 col-md-5">
      <a href="/">
      <img src="https://res.cloudinary.com/djoztpm50/image/upload/v1569477874/Logo_login_pb5x9z.png" class="user"></img>
      </a>
        <form action="javascript:void(0)" id="form">
        <p className="registrationheading">Sign up</p>
             <div className="insideFormregistration">
             
             
              <div className="col-md-12 col-xs-12 col-sm-12">
              <p
                  style={{
                    display: this.state.invalidPws,
                    color: "red",
                    fontSize:"13px",
                    marginTop: "10px"
                  }}
                >
                *  invalid password
                </p> 

                
             <label htmlFor="name" className="loginLabel">
                  Name*
                </label>
                <input
                  className="form-control loginEntryField"
                  type="text"
                  id="name"
                 
                  
                  onInput={() => this.validateName()}
                />
                <p
                  style={{
                    display: this.state.displayName,
                    fontSize:"13px",
                    color: "red",
                    marginTop: "10px"
                  }}
                >
                  * This field is required
                </p>
             
             </div>


             <div className="col-md-12 col-xs-12 col-sm-12"> 
                <label htmlFor="email" className="loginLabel">
                Email* 
                </label>
                <input
                  className="form-control loginEntryField"
                  type="email"
                  id="email"
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                  
                  onInput={() => this.validateEmail()}
                />
                <p
                  style={{
                    display: this.state.displayEmail,
                    fontSize:"13px",
                    color: "red",
                    marginTop: "10px"
                  }}
                >
                   This field is required
                </p>
                 <p
                  style={{
                    display: this.state.displayEmailInvalid,
                    color: "red",
                    fontSize:"13px",
                    marginTop: "10px"
                  }}
                >
                *  The Entered Email id is not valid
                </p> 
                <p style={{
                    display: this.state.alreadyhaveemail,
                    color: "red",
                    fontSize:"13px",
                    marginTop: "10px"
                  }}
                >
                   * this email already exists
                </p>
             </div>
            

             <div className="col-md-12 col-xs-12 col-sm-12">
                   <label htmlFor="password" className="loginLabel">
                      Password *
                    </label>
                    <input
                      className="form-control loginEntryField"
                      type="password"
                      id="password"
                      // required
                      onInput={() => this.validatePassword()}
                    />
                     <p
                      style={{
                        display: this.state.displayPassword,
                        color: "red",
                        marginTop: "10px"
                      }}
                    >
                      * This field is required
                    </p>
                    <p
                      style={{
                        display: this.state.displayInvalidPassword,
                        color: "red",
                        marginTop: "10px"
                      }}
                    >
                      * Must contain at least one number and one uppercase and lowercase letter,
                       and at least 8 or more characters
                    </p>
                   </div>









             {/* <div className="col-md-12 col-xs-12 col-sm-12">
             <label htmlFor="password" className="loginLabel">
               Password
                </label>
                <input
                  className="form-control loginEntryField"
                  type="password"
                  id="password"
                  
                  onInput={() => this.validatePassword()}
                />
                <p
                  style={{
                    display: this.state.displayPassword,
                    fontSize:"13px",
                    color: "red",
                    marginTop: "10px"
                  }}
                >
                  * This field is required
                </p>
             
             </div> */}

            <div className="loginbtndiv">

              <button 
              className="loginBtn" id="signupbutton"
              onClick={() => this.proceedtoLogin()}
                     
              >Sign Up
              <img src="https://res.cloudinary.com/djoztpm50/image/upload/v1551866451/Native%20Circle/path_1.svg" class="loginarrow"/>
                </button>
               
              </div>
              <p className="accountQuestion">have an account?<span> <a className="signupclass" href="/login/">Login.</a></span></p>
           </div>

          </form>
      </div>
        </div>

        </div>
        <Footer />
          </div>
        </Spin>
          </div>
    );
  }
}
export default Registration;
// export const pageQuery = graphql`
// query Registration {
//   allMysqlLeads {
//     edges {
//       node {
//         email
        
//       }
//     }
//   }
// }
// `;

